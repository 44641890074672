import React from "react"
import styles from "./featured-post.module.scss"
import postMetaStyles from "../../templates/blog-post.module.scss"
import { Link } from "gatsby"

import Tag from "../article/tag"

export default (props) => {
  const post = props.post

  return (
    <section className={styles.featuredPost}>
      <Link to={post.fields.slug}>
        <h1>{post.frontmatter.title}</h1>

        <section className={postMetaStyles.postMetaSection}>
          <h4 className={postMetaStyles.postMeta}>{post.frontmatter.date}</h4> <span className={styles.metaDivider}>/</span> <h4
            className={postMetaStyles.postMeta}>{post.timeToRead} min read</h4> <span className={styles.metaDivider}>/</span>

            {post.frontmatter.tags.map(tag => (
              <Tag key={tag} tag={tag}/>
            ))}
        </section>
        < p> {post.frontmatter.excerpt || post.excerpt}</p>
      </Link>
    </section>
  )
}
