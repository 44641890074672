import React from "react"
import { Link, graphql } from "gatsby"

import Container from "../components/container"
import Metadata from "../components/metadata"
import FeaturedPost from "../components/home/featured-post"
import PreviousArticles from "../components/home/previous-articles"
import RegularButton from "../components/form/regular-button"

export default ({ data }) => {
  const postsData = data.allMarkdownRemark.edges
  const latestPost = postsData[0].node
  const remainingPosts = postsData.slice(1)

  return (
    <Container>
      <Metadata
        title={data.site.siteMetadata.description}
        description="The premier & fun blog on Kotlin by Rahul Chowdhury. Updated every Sunday."
        socialTitle="OkKotlin"
      />

      <FeaturedPost post={latestPost}/>

      {remainingPosts.length > 0 &&
      <section>
        <PreviousArticles posts={remainingPosts}/>

        <Link to="/articles">
          <RegularButton text="View all articles" style={{ marginTop: `25px` }}/>
        </Link>
      </section>
      }
    </Container>
  )
}

export const query = graphql`
query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 6
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            tags
            excerpt
          }
          excerpt
          timeToRead
          fields {
            slug
          }
        }
      }
    }
  }
`
