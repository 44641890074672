import React from "react"
import styles from "./previous-articles.module.scss"

import IconHeading from "../heading/icon-heading"
import ArticlePreview from "../list/article-preview"

import ListIcon from "../images/heading-list.svg"

export default (props) => (
  <section className={styles.previousArticlesSection}>
    <IconHeading text="Previous weeks" image={ListIcon} style={{ marginBottom: -15 + "px" }}/>

    {props.posts.map(({ node }) => (
      <div key={node.id}>
        <ArticlePreview post={node}/>
      </div>
    ))}
  </section>
)
